<template>
  <el-main>
    <el-row :gutter="20">
      <el-col :span="12">
        <el-card class="box-card">
          <div slot="header" class="clearfix">
            <span>创建评论任务</span>
          </div>
          <el-form ref="form" :model="form" label-width="80px">
            <el-form-item label="平台" prop="platform_id">
              <el-select v-model="form.platform_id" placeholder="全部" clearable style="width: 100%">
                <el-option v-for="item in options.platform" :key="item.id" :label="item.name"
                           :value="item.id"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item>
              <el-upload
                  class="upload-demo"
                  :headers="headers"
                  :action="uploadUrl"
                  :limit="1"
                  :on-success="handleUpload"
                  :show-file-list="showFiles"
                  :file-list="files">
                <el-button size="small" type="primary">点击上传链接</el-button>
                <div slot="tip" class="el-upload__tip">只能上传xlsx文件，且不超过10m</div>
              </el-upload>
            </el-form-item>
            <el-form-item label="备注">
              <el-input type="textarea" v-model="form.remark"></el-input>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" @click="onSubmit()">创建任务</el-button>
            </el-form-item>
          </el-form>
        </el-card>
        <el-card style="margin-top: 20px">
          <div slot="header" class="clearfix">
            <span>链接预览</span>
            <span>（{{ urls.length }}个）</span>
          </div>
          <div>
            <p v-for="item in urls" :key="item"><a :href="item" target="_blank">{{ item }}</a></p>
          </div>
        </el-card>
      </el-col>
      <el-col :span="12">
        <SupportFields></SupportFields>
      </el-col>
    </el-row>
  </el-main>
</template>

<script>
import SupportFields from "@/components/SupportFields";

export default {
  name: "TaskEditorComment",
  components: {'SupportFields': SupportFields},
  created() {
    let me = this;
    me.axios.post('/api/task/getCreatableTasks', {type: 2}).then(res => {
      res.forEach(platform => {
        me.options.platform.push(platform);
      })
    })
    this.uploadUrl = this.const.baseURL + '/api/job/uploadUrl';
  },
  data() {
    return {
      files: [],
      urls: [],
      form: {
        platform_id: null,
        remark: ''
      },
      options: {
        platform: []
      },
      headers: {
        token: localStorage.getItem("token")
      },
      uploadUrl: '',
      showFiles: false
    }
  },
  methods: {
    handleUpload(response, file, files) {
      files.pop();
      let me = this;
      switch (response.code) {
        case 0:
          me.urls = [];
          response.data.forEach(val => {
            me.urls.push(val);
          });
          me.loadWordCloud();
          break;
        case 1:
          this.$message.error(response.msg);
          break;
        case 2:
          this.$router.push('/login');
          break;
      }
    },
    onSubmit() {
      if (this.form.platform_id == null) {
        this.$message.error("请选择一个平台");
        return;
      }
      if (this.urls.length == 0) {
        this.$message.error("请添加至少一个发文链接");
        return;
      }
      let params = {};
      params['type'] = 2;
      params['platform_id'] = this.form.platform_id;
      params['remark'] = this.form.remark;
      params['urls'] = JSON.stringify(this.urls);
      let me = this;
      me.axios.post('/api/job/add', params).then(() => {
        me.$router.push('/home/task/list');
      });
    }
  }
}
</script>

<style scoped>

</style>